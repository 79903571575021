import {
  type LytxPreEvent,
  type LytxPreEventData,
  type SurfSightEvent,
  type SurfSightEventData,
  type WebSocketEvents,
  type WebSocketEventsTop,
} from '../WebsocketTypes';

export const isValidEventData = (data: unknown): data is WebSocketEventsTop => {
  return data != null && typeof data === 'object' && 'type' in data;
};

export const isLytxEventData = (data: unknown): data is WebSocketEventsTop => {
  return (
    data != null &&
    typeof data === 'object' &&
    'type' in data &&
    'data' in data &&
    data.data != null &&
    typeof data.data === 'object' &&
    'data' in data.data &&
    data.data.data != null &&
    typeof data.data.data === 'object' &&
    'metadata' in data.data.data &&
    data.data.data.metadata != null &&
    typeof data.data.data.metadata === 'object' &&
    !('providerName' in data.data.data.metadata)
  );
};

export const isActiveLytxEvent = (data: unknown): data is WebSocketEvents => {
  return (
    data != null &&
    typeof data === 'object' &&
    'data' in data &&
    data.data != null &&
    typeof data.data === 'object' &&
    'metadata' in data.data &&
    data.data.metadata != null &&
    typeof data.data.metadata === 'object' &&
    !('providerName' in data.data.metadata)
  );
};

export const isSurfSightEventData = (data: unknown): data is SurfSightEvent => {
  if (
    data == null ||
    typeof data !== 'object' ||
    !('type' in data) ||
    !('data' in data)
  ) {
    return false;
  }

  const dataObj = data as {data: unknown};

  if (
    dataObj.data == null ||
    typeof dataObj.data !== 'object' ||
    !('data' in dataObj.data)
  ) {
    return false;
  }

  const nestedData = dataObj.data as {data: unknown};

  if (
    nestedData.data == null ||
    typeof nestedData.data !== 'object' ||
    !('metadata' in nestedData.data)
  ) {
    return false;
  }

  const {metadata} = nestedData.data as {metadata: unknown};

  if (
    metadata == null ||
    typeof metadata !== 'object' ||
    !('providerName' in metadata)
  ) {
    return false;
  }

  const {providerName} = metadata as {providerName: unknown};

  return providerName === 'surfsight';
};

export const isActiveSurfSightEvent = (
  data: unknown,
): data is SurfSightEventData => {
  return (
    data != null &&
    typeof data === 'object' &&
    'data' in data &&
    data.data != null &&
    typeof data.data === 'object' &&
    'metadata' in data.data &&
    data.data.metadata != null &&
    typeof data.data.metadata === 'object' &&
    'providerName' in data.data.metadata &&
    data.data.metadata.providerName === 'surfsight'
  );
};

export const isLytxPreEventData = (data: unknown): data is LytxPreEvent => {
  if (
    data == null ||
    typeof data !== 'object' ||
    !('type' in data) ||
    !('data' in data)
  ) {
    return false;
  }

  const dataObj = data as {data: unknown};

  if (
    dataObj.data == null ||
    typeof dataObj.data !== 'object' ||
    !('id' in dataObj.data) ||
    !('data' in dataObj.data) ||
    !('pop_receipt' in dataObj.data) ||
    !('next_visible_on' in dataObj.data) ||
    !('expires_on' in dataObj.data)
  ) {
    return false;
  }

  const nestedData = dataObj.data as {data: unknown};

  if (
    nestedData.data == null ||
    typeof nestedData.data !== 'object' ||
    !('data' in nestedData.data) ||
    !('metadata' in nestedData.data) ||
    !('logging' in nestedData.data)
  ) {
    return false;
  }

  const deepData = nestedData.data as {data: unknown};

  if (deepData.data == null || typeof deepData.data !== 'object') {
    return false;
  }

  const requiredDataKeys = [
    'dceFileName',
    'eventTriggerTypeId',
    'eventTriggerSubTypeId',
    'recordDateUTC',
    'recordDateTZ',
    'recordDateUTCOffset',
    'erSerialNumber',
  ];

  const innerData = deepData.data as Record<string, unknown>;
  return requiredDataKeys.every((key) => key in innerData);
};

export const isActiveLytxPreEvent = (
  data: unknown,
): data is LytxPreEventData => {
  if (
    data == null ||
    typeof data !== 'object' ||
    !('id' in data) ||
    !('data' in data)
  ) {
    return false;
  }

  const dataObj = data as {data: unknown};

  if (
    dataObj.data == null ||
    typeof dataObj.data !== 'object' ||
    !('metadata' in dataObj.data) ||
    !('data' in dataObj.data)
  ) {
    return false;
  }

  const nestedData = dataObj.data as {metadata: unknown; data: unknown};

  if (
    nestedData.metadata == null ||
    typeof nestedData.metadata !== 'object' ||
    (nestedData.metadata as {eventType: unknown}).eventType !== 'pre-event'
  ) {
    return false;
  }

  if (nestedData.data == null || typeof nestedData.data !== 'object') {
    return false;
  }

  const requiredDataKeys = [
    'dceFileName',
    'eventTriggerTypeId',
    'eventTriggerSubTypeId',
    'recordDateUTC',
    'recordDateTZ',
    'recordDateUTCOffset',
    'erSerialNumber',
  ];

  const deepData = nestedData.data as Record<string, unknown>;
  return requiredDataKeys.every((key) => key in deepData);
};
