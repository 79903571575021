import {useMemo} from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import Send from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {
  type EarlyWarningEventComment,
  PostReviewDetailsNotificationsBehaviourOutcomesResponse,
} from '@onroadvantage/onroadvantage-api';
import {useAuth} from 'oidc-react';
import {useLocation} from 'react-router-dom';

import {NotificationContactRow} from './NotificationContactRow';
import {VantageSelectField} from '../../../../common/components/form/VantageSelectField';
import {VantageTextField} from '../../../../common/components/form/VantageTextField';
import {
  type DeletableComment,
  useNotificationContacts,
} from '../../../hooks/useNotificationContacts';

export function NotificationContacts({
  contacts,
  eventDetailsComments,
  reviewer,
}: {
  contacts?: Array<PostReviewDetailsNotificationsBehaviourOutcomesResponse>;
  eventDetailsComments?: EarlyWarningEventComment[];
  reviewer?: string;
}) {
  const {
    comment,
    setComment,
    handleCommentSubmit,
    commentsList,
    handleCallTriggered,
    handleCommentDelete,
    handleReasonCode,
    REASON_CODE_UNANSWERED,
    REASON_CODE_ANSWERED,
    callTriggeredContactNames,
  } = useNotificationContacts({eventDetailsComments});
  const theme = useTheme();
  const {pathname} = useLocation();
  const auth = useAuth();

  const eventReviewer = useMemo(
    () => reviewer ?? auth.userData?.profile?.email,
    [auth.userData?.profile?.email, reviewer],
  );

  return (
    <Stack
      sx={{
        backgroundColor: '#EFEFEF',
        borderRadius: '16px',
        padding: '12px 12px 0 12px',
        height: '100%',
        marginTop: '16px',
      }}
    >
      <TableContainer>
        <Table aria-label="notifications table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="h5" component="h2">
                  Notifications
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Phone</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">Email</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="subtitle2">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contacts != null &&
              contacts?.length > 0 &&
              contacts
                .sort((a, b) => {
                  const priorityA =
                    'priority' in a && a.priority !== undefined
                      ? a.priority
                      : Infinity;
                  const priorityB =
                    'priority' in b && b.priority !== undefined
                      ? b.priority
                      : Infinity;
                  return Number(priorityA) - Number(priorityB);
                })
                .map((contact) => {
                  if ('id' in contact) {
                    return (
                      <NotificationContactRow
                        key={contact.id}
                        {...contact}
                        onCallClicked={handleCallTriggered}
                      />
                    );
                  }
                  return null;
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Comments
        </Typography>
        <Stack direction="row">
          <List sx={{width: '50%'}}>
            {commentsList.map((notificationComment) => (
              <ListItem
                key={notificationComment.text}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  minHeight: '76px',
                  width: '100%',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '16px',
                  marginBottom: '12px',
                }}
              >
                <Typography
                  fontSize={'12px'}
                  position={'absolute'}
                  top={'8px'}
                  fontWeight={400}
                  lineHeight={1.43}
                  left={'16px'}
                  color={theme.palette.text.secondary}
                >
                  {eventReviewer}
                </Typography>
                <ListItemText
                  primary={notificationComment.text}
                  secondary={notificationComment?.commentTime?.toLocaleString()}
                  secondaryTypographyProps={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                    fontSize: '12px',
                  }}
                  sx={{
                    margin: '16px 0',
                  }}
                />
                <IconButton
                  aria-label="delete"
                  sx={{
                    height: '24px',
                    width: '24px',
                    position: 'absolute',
                    right: '4px',
                    bottom: '8px',
                  }}
                  onClick={() => {
                    handleCommentDelete(
                      notificationComment as DeletableComment,
                    );
                  }}
                >
                  <DeleteIcon sx={{height: '20px', width: '20px'}} />
                </IconButton>
              </ListItem>
            ))}
          </List>

          {callTriggeredContactNames.length > 0 && (
            <Stack direction="column" width="50%">
              {callTriggeredContactNames.map((callTriggeredContactName) => {
                const answeredLabel = REASON_CODE_ANSWERED(
                  callTriggeredContactName,
                );
                const unansweredLabel = REASON_CODE_UNANSWERED(
                  callTriggeredContactName,
                );
                return (
                  <Box p={2} pt="12px" key={callTriggeredContactName}>
                    <VantageSelectField
                      name="reasonCode"
                      options={[answeredLabel, unansweredLabel]}
                      onChange={(e) => {
                        handleReasonCode(e);
                      }}
                      label={`What was the call outcome for ${callTriggeredContactName}?`}
                    />
                  </Box>
                );
              })}
            </Stack>
          )}
        </Stack>
        <Stack alignItems="flex-end">
          <VantageTextField
            name="message"
            placeholder="Enter your comments here"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            multiline
            sx={{marginBottom: '12px'}}
            disabled={pathname.includes('videoEvent')}
          />
          <Button
            onClick={handleCommentSubmit}
            variant="contained"
            color="primary"
            endIcon={<Send />}
            disabled={comment.trim() === '' || pathname.includes('videoEvent')}
            sx={{width: 'fit-content'}}
          >
            Add Comment
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
}
