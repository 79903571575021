import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from '../../router/ProtectedRoute';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyInclusionConfig = lazy(
  async () => await import('./pages/InclusionConfig'),
);
const LazyInclusionConfigAdd = lazy(
  async () => await import('./pages/InclusionConfigAdd'),
);
const LazyInclusionConfigDetail = lazy(
  async () => await import('./pages/InclusionConfigDetail'),
);

const LazyHindsightConfig = lazy(
  async () => await import('./pages/HindsightConfig'),
);
const LazyHindsightConfigDetail = lazy(
  async () => await import('./pages/HindsightConfigDetail'),
);
const LazyEventCount = lazy(async () => await import('./pages/EventCount'));

export const earlyWarningRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => redirect(routes.earlyWarning.triggerInclusionConfig),
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.triggerInclusionConfig,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['triggerConfig:view']}>
        <LazyInclusionConfig />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Inclusion Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {label: 'Trigger Config'},
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.triggerInclusionConfigAdd,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['triggerConfig:add']}>
        <LazyInclusionConfigAdd />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Inclusion Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {
            label: 'Trigger Config',
            path: routes.earlyWarning.triggerInclusionConfig,
          },
          {label: 'Trigger Config Add'},
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.triggerInclusionConfigId,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['triggerConfig:edit']}>
        <LazyInclusionConfigDetail />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Inclusion Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {
            label: 'Trigger Config',
            path: routes.earlyWarning.triggerInclusionConfig,
          },
          {label: 'Trigger Config Details'},
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.hindsightConfig,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['hindsightConfig:view']}>
        <LazyHindsightConfig />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Hindsight Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {
            label: 'Hindsight Config',
          },
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.hindsightConfigId,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['hindsightConfig:view']}>
        <LazyHindsightConfigDetail />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Hindsight Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {
            label: 'Hindsight Config',
            path: routes.earlyWarning.hindsightConfig,
          },
          {label: 'Trigger Config Details'},
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.earlyWarning.eventCount,
      routes.earlyWarning.base,
    ),
    element: (
      <ProtectedRoute permissions={['earlyWarningEventCount:view']}>
        <LazyEventCount />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Early Warning Event Count';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Master Data'},
          {label: 'Early Warning Details'},
          {label: 'Early Warning Event Count'},
        ],
      });

      return null;
    },
  },
];
