import {type ReactNode, useCallback, useEffect} from 'react';

import Stack from '@mui/material/Stack';
import {AuthProvider, useAuth, UserManager} from 'oidc-react';

import {config} from '../../../config';
import {routes} from '../../../routes';
import {clearOidcLocalStorage} from '../../auth/helpers/clearOidcLocalStorage';
import {Loader} from '../components/loader/Loader';
import {useAuthStore} from '../stores/authStore';
import {useEventStore} from '../stores/eventStore';
import {useUserStore} from '../stores/userStore';

const userManager = new UserManager(config.sso.oidcConfig);

export function AuthWrapper({children}: {children: ReactNode}) {
  return (
    <AuthProvider userManager={userManager} autoSignIn={false}>
      <AuthWrapperHandler>{children}</AuthWrapperHandler>
    </AuthProvider>
  );
}

function AuthWrapperHandler({children}: {children: ReactNode}) {
  const resetAuthStore = useAuthStore((state) => state.reset);
  const resetUserStore = useUserStore((state) => state.reset);
  const ssoToken = useAuthStore((state) => state.ssoToken);
  const setSsoToken = useAuthStore((state) => state.setSsoToken);
  const setSubject = useAuthStore((state) => state.setSubject);
  const setAuthStatus = useAuthStore((state) => state.setAuthStatus);
  const eventsCountTimeoutRef = useEventStore(
    (state) => state.eventsCountTimeoutRef,
  );
  const auth = useAuth();

  const logout = useCallback(async () => {
    window.open(routes.auth.login, '_self');
    resetAuthStore();
    resetUserStore();
    if (eventsCountTimeoutRef != null) {
      clearTimeout(eventsCountTimeoutRef);
    }
    clearOidcLocalStorage();
    await auth.userManager.clearStaleState();
    await auth.userManager.revokeTokens();
    try {
      await auth.userManager.signoutSilent();
    } catch {
      await auth.userManager.revokeTokens();
    }
  }, [auth, eventsCountTimeoutRef, resetAuthStore, resetUserStore]);

  const attemptLogin = useCallback(async () => {
    setAuthStatus('authenticating');
    try {
      const response = await auth.userManager.signinSilent();
      if (response?.access_token == null) {
        await logout();
      }
    } catch {
      await logout();
    }
  }, [auth, logout, setAuthStatus]);

  useEffect(() => {
    if (auth.userData?.access_token != null) {
      setSsoToken(auth.userData?.access_token);
      setSubject(auth.userData.profile.sub);
    }
  }, [
    auth.userData?.access_token,
    auth.userData?.profile.sub,
    setSsoToken,
    setSubject,
  ]);

  useEffect(() => {
    if (auth.userData?.expires_in == null) {
      return () => {};
    }

    const refreshToken = async () => {
      try {
        await auth.userManager.signinSilent();
      } catch {
        await logout();
      }
    };

    let timeout = (auth.userData.expires_in - 5) * 1000;

    if (auth.userData.expires_in > 250) {
      timeout = (auth.userData.expires_in - 100) * 1000;
    }

    const timeoutId = setTimeout(() => {
      void refreshToken();
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [auth, logout]);

  useEffect(() => {
    auth.userManager.startSilentRenew();

    const handleAccessTokenExpired = () => {
      void attemptLogin();
    };

    auth.userManager.events.addAccessTokenExpired(handleAccessTokenExpired);

    return () => {
      auth.userManager.stopSilentRenew();
      auth.userManager.events.removeAccessTokenExpired(
        handleAccessTokenExpired,
      );
    };
  }, [attemptLogin, auth, ssoToken]);

  useEffect(() => {
    userManager.events.addUserSignedOut(logout);

    return () => {
      userManager.events.removeUserSignedOut(logout);
    };
  }, [logout]);

  if (auth.isLoading) {
    return (
      <Stack flex={1} alignItems="center" justifyContent="center">
        <Loader label="Authenticating" />
      </Stack>
    );
  }

  return children;
}
