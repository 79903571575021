import type {Dispatch, SetStateAction} from 'react';

import {captureException} from '@sentry/react';

import {earlyWarningEventApi} from '../../../api.ts';
import type {
  LytxPreEvent,
  LytxPreEventData,
  SurfSightEventData,
  WebSocketEvents,
} from '../WebsocketTypes.ts';
import {isActiveLytxPreEvent} from './eventDistinctions.ts';

export const getLytxPreEventUrl = async (
  eventData: LytxPreEvent,
  setEvents: Dispatch<
    SetStateAction<
      Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>
    >
  >,
): Promise<void> => {
  if ('signedMp4Uri' in eventData.data.data.data) {
    return;
  }
  try {
    const fileName = eventData.data.data.data.dceFileName;
    const eventId = eventData.data.id;
    const response =
      await earlyWarningEventApi.apiEarlyWarningEventPreEventVideoUrlGet({
        fileName,
      });

    if (response?.videoURL != null) {
      setEvents((currentEvents) =>
        currentEvents.map((event) => {
          if (isActiveLytxPreEvent(event) && event.id === eventId) {
            const updatedEvent = {...event} as LytxPreEventData;
            if (response.videoURL != null) {
              updatedEvent.data.data.signedMp4Uri = response.videoURL;
            }
            return updatedEvent;
          }
          return event;
        }),
      );
    }
  } catch (e) {
    captureException(e);
  }
};
