import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {NextEvent} from './NextEvent';
import {config} from '../../../config';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {useEventReviewBottomBar} from '../hooks/useEventReviewBottomBar';

export function EventReviewBottomBar() {
  const {webSocket} = useEventReviewContext();
  const {
    handleLateClip,
    navigateBackToVideo,
    navigationDrawerOpen,
    handleNavigateBack,
    handleNoIssue,
    noIssueSelected,
    handleSessionPause,
    noIssueDisabled,
    nextEventAvailable,
    feedbackFormRef,
    handleFaultyClip,
    faultyClipSelected,
    lateClipSelected,
    nextDisabled,
    takeBreakMessage,
  } = useEventReviewBottomBar();

  return (
    <StyledTopBar navigationDrawerOpen={navigationDrawerOpen}>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '100%',
          backgroundColor: '#CCCCCC',
        }}
        onClick={handleNavigateBack}
      >
        <KeyboardBackspaceIcon sx={{color: '#4C4C4C'}} />
      </IconButton>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '100%',
          backgroundColor: '#CCCCCC',
        }}
        onClick={navigateBackToVideo}
      >
        <PlayArrowIcon sx={{color: '#4C4C4C'}} />
      </IconButton>
      <SelectedFormValueButton
        $selected={lateClipSelected}
        onClick={handleLateClip}
      >
        <Typography fontSize="12px" fontWeight={700}>
          LATE CLIP
        </Typography>
      </SelectedFormValueButton>
      <SelectedFormValueButton
        $selected={faultyClipSelected}
        onClick={handleFaultyClip}
      >
        <Typography fontSize="12px" fontWeight={700}>
          CLIP ERROR
        </Typography>
      </SelectedFormValueButton>

      <SelectedFormValueButton
        onClick={() => {
          handleNoIssue();
        }}
        $selected={noIssueSelected}
        disabled={noIssueDisabled}
      >
        <Typography fontSize="12px" fontWeight={700}>
          NO ISSUE
        </Typography>
      </SelectedFormValueButton>
      {webSocket?.eventCount != null ? (
        <Tooltip
          title={`There are ${webSocket?.eventCount} events in the queue`}
        >
          <Chip label={webSocket?.eventCount} style={{cursor: 'default'}} />
        </Tooltip>
      ) : null}
      <div
        style={{
          position: 'absolute',
          right: '48px',
          display: 'flex',
          columnGap: '50px',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            backgroundColor:
              nextDisabled || webSocket.planToTakeBreak ? '#4c4c4c' : '#333333',
            opacity: nextDisabled || webSocket.planToTakeBreak ? 0.3 : 1,
            borderRadius: '16px',
            p: '3px 12px',
            height: '32px',
            marginLeft: 'auto',
          }}
          disabled={nextDisabled || webSocket.planToTakeBreak}
          onClick={handleSessionPause}
        >
          <LocalCafeOutlinedIcon sx={{color: '#FDFBE9', height: '16px'}} />
          <Typography fontSize="12px" color="#FDFBE9" fontWeight={700}>
            {takeBreakMessage}
          </Typography>
        </Button>
        <NextEvent
          feedbackFormRef={feedbackFormRef}
          nextEventAvailable={nextEventAvailable}
          nextDisabled={nextDisabled && !webSocket.planToTakeBreak}
        />
      </div>
    </StyledTopBar>
  );
}

const StyledTopBar = styled(Stack)(
  ({navigationDrawerOpen}: {navigationDrawerOpen: boolean}) => ({
    position: 'fixed',
    bottom: 0,
    width: !navigationDrawerOpen ? '100%' : 'calc(100% - 255px)',
    backgroundColor: '#EFEFEF',
    color: '#4C4C4C',
    height: '54px',
    padding: '10px 48px',
    borderRadius: '0 0 16px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '10px',
    fontWeight: 700,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    '& .MuiChip-root, button': {
      cursor: 'pointer',
      color: '#4C4C4C',
    },
  }),
);

const SelectedFormValueButton = styled(Button)(
  ({$selected}: {$selected: boolean}) => ({
    backgroundColor: $selected ? config.theme.primaryDefault : '#CCCCCC',

    ' .MuiTypography-root': {
      color: $selected ? '#FFFFFF' : '#4c4c4c',
    },
    ':hover': {
      backgroundColor: $selected && config.theme.primaryDefault,
    },
    '&.Mui-disabled': {
      backgroundColor: $selected ? config.theme.primaryDefault : '#CCCCCC',
      opacity: $selected ? 1 : 0.5,
    },
    transition:
      'background-color 150ms ease-in-out, color 150ms ease-in-out, opacity 150ms ease-in-out',
  }),
);
