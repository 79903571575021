import AssessmentIcon from '@mui/icons-material/AssessmentRounded';

import {type HomeMenuItemCard} from './homeMenuItems';
import {routes} from '../../../routes';

export const analysisHomeMenuItems: HomeMenuItemCard = {
  title: 'Analysis',
  icon: <AssessmentIcon color="action" />,
  menuItems: [
    {
      label: 'Dashboards',
      path: routes.dashboard.list,
      permissions: ['dashboard:list'],
    },
    {
      label: 'Reports',
      path: routes.report.list,
      permissions: ['report:list'],
    },
    // {
    //   label: 'Fatigue',
    //   path: routes.fatigue,
    // },
  ],
};
