import {RefObject, useEffect} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {routes} from '../../../routes';

type WebSocketRef = RefObject<WebSocket | null>;

const isWebSocketOpen = (wsRef: WebSocketRef) => {
  return wsRef?.current?.readyState === WebSocket.OPEN;
};

const isWebSocketConnecting = (wsRef: WebSocketRef) => {
  return wsRef?.current?.readyState === WebSocket.CONNECTING;
};

export const useRedirectToBaseIfNotConnected = ({
  wsRef,
}: {
  wsRef: WebSocketRef;
}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !isWebSocketOpen(wsRef) &&
      !isWebSocketConnecting(wsRef) &&
      pathname !== routes.eventReview.base &&
      !pathname.includes('videoEvents')
    ) {
      if (wsRef != null) {
        wsRef?.current?.close();
      }
      navigate(routes.eventReview.base);
      toast('You have to start your session to view this page', {
        type: 'info',
      });
    }
  }, [wsRef, navigate, pathname]);
};
