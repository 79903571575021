import {ReactNode} from 'react';

import {styled} from '@mui/material/styles';
import Tab, {type TabProps} from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';

import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface VantageTabProps<TabValue = string>
  extends Omit<TabProps, 'value' | 'label'> {
  value: TabValue;
  label: ReactNode;
  size?: 'small' | 'default';
  tooltip?: string; // New optional tooltip prop
}

export function VantageTab({
  value,
  label,
  size = 'small',
  tooltip,
  ...tabProps
}: VantageTabProps) {
  const tabElement = (
    <StyledTab value={value} label={label} $size={size} {...tabProps} />
  );

  return tooltip ? (
    <Tooltip title={tooltip} arrow>
      <span>{tabElement}</span>
    </Tooltip>
  ) : (
    tabElement
  );
}

const StyledTab = styled(Tab, {
  shouldForwardProp: addShouldNotForwardProps('$size'),
})<{
  $size?: 'small' | 'default';
}>(({theme, $size}) => ({
  textTransform: 'none',
  minWidth: $size === 'small' ? '90px' : '110px',
  minHeight: 'auto',
  padding: $size === 'small' ? theme.spacing(1, 2) : theme.spacing(1.5, 2),
  '& .MuiTab-iconWrapper': {
    width: '0.9em',
    height: '0.9em',
  },
}));
