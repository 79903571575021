import {useCallback, useMemo} from 'react';

import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {useCheckUnselectNoIssue} from './useCheckUnselectNoIssue';
import {useFormValues} from './useFormValues';
import {routes} from '../../../routes';
import {useNavigationStore} from '../../common/stores/navigationStore';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {
  hasOnlySoundBehaviours,
  processLookupObject,
} from '../utils/feedbackUtils';

export const useEventReviewBottomBar = () => {
  const {
    formRef,
    webSocket,
    behaviours,
    handleLateClip,
    handleFaultyClip,
    handleNoIssue,
    notificationComments,
    hindsightBehaviourOutcomeData,
  } = useEventReviewContext();

  const navigate = useNavigate();
  const formValues = useFormValues(formRef);
  const navigationDrawerOpen = useNavigationStore(
    (state) => state.navigationDrawerOpen,
  );

  useCheckUnselectNoIssue({
    formRef,
    behaviours,
  });

  const nextEventAvailable = useMemo(
    () => webSocket.events.length > 1,
    [webSocket.events.length],
  );

  const emptyForm = useMemo(
    () => !Object.values(formValues).some(Boolean),
    [formValues],
  );

  const cleanedKeys = useMemo(
    () => processLookupObject(formValues),
    [formValues],
  );
  const onlySoundSelected = hasOnlySoundBehaviours(cleanedKeys, behaviours);

  const noIssueSelected = useMemo(
    () => formValues['__lookupKey: n__No Issue'],
    [formValues],
  );

  const noIssueDisabled = useMemo(
    () => !onlySoundSelected && !noIssueSelected && !emptyForm,
    [noIssueSelected, onlySoundSelected, emptyForm],
  );

  const nextDisabled = useMemo(
    () => emptyForm || (onlySoundSelected && !noIssueSelected),
    [emptyForm, onlySoundSelected, noIssueSelected],
  );

  const faultyClipSelected = useMemo(
    () => formValues['__lookupKey: ce__Clip Error'],
    [formValues],
  );

  const lateClipSelected = useMemo(
    () =>
      notificationComments.length > 0 &&
      notificationComments[0].text.includes('Late Clip'),
    [notificationComments],
  );

  const navigateBackToVideo = useCallback(() => {
    navigate(routes.eventReview.review);
  }, [navigate]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSessionPause = useCallback(async () => {
    try {
      webSocket.setPlanToTakeBreak(true);
      await formRef?.current?.submitForm();
    } catch {
      toast('Error pausing session', {type: 'error'});
    }
  }, [webSocket, formRef]);

  const takeBreakMessage = useMemo(() => {
    if (webSocket.planToTakeBreak && !webSocket.showOnBreakModal) {
      return 'YOU CAN BREAK AFTER NEXT EVENT';
    } else if (webSocket.showOnBreakModal) {
      return 'YOU CAN BREAK AFTER THIS EVENT';
    }
    return 'SUBMIT AND TAKE A BREAK';
  }, [webSocket.planToTakeBreak, webSocket.showOnBreakModal]);

  return {
    handleLateClip,
    navigationDrawerOpen,
    navigateBackToVideo,
    handleNavigateBack,
    handleNoIssue,
    handleSessionPause,
    noIssueDisabled,
    nextEventAvailable,
    feedbackFormRef: formRef,
    handleFaultyClip,
    faultyClipSelected,
    noIssueSelected,
    lateClipSelected,
    emptyForm,
    nextDisabled,
    hindsightBehaviourOutcomeData,
    takeBreakMessage,
  };
};
