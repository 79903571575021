import {useEffect, useMemo} from 'react';

import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import {useEventReviewContext} from '../contexts/EventReviewContext.tsx';

interface OnBreakPopupProps {
  open: boolean;
  onClose: () => void;
}

export function OnBreakPopup({open, onClose}: OnBreakPopupProps) {
  const {webSocket} = useEventReviewContext();
  const queueLength = useMemo(
    () => webSocket.submissionQueue.length,
    [webSocket.submissionQueue],
  );

  useEffect(() => {
    if (queueLength === 0 && open) {
      webSocket.disconnect();
    }
  }, [queueLength, open, webSocket]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>You are on break</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '12px',
        }}
      >
        <LocalCafeOutlinedIcon sx={{height: '132px', width: '132px'}} />
        <Typography fontSize="16px">
          You have paused your review session.
        </Typography>
        <Typography fontSize="16px">
          In this time, new events will not be assigned to you.
        </Typography>
        <Typography fontSize="16px">
          You may resume your session when your submission queue is empty.
        </Typography>
        <Typography fontSize="16px">
          You have {webSocket.submissionQueue.length} event(s) being processed
          in the background.
        </Typography>
        <DialogActions sx={{mt: 2, width: '100%'}}>
          <Button
            onClick={onClose}
            variant="contained"
            fullWidth
            disabled={webSocket.submissionQueue.length !== 0}
          >
            <Typography fontSize="16px">Continue</Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
