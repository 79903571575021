import {contactApi} from '../../../api';
import {GetFilter} from '../../common/hooks/useFiltering.ts';
import {useTableDownload} from '../../common/hooks/useTableDownload';

export const useDownloadAudiences = (getFilter: GetFilter) => {
  return useTableDownload('Audiences', async () => {
    const {items} = await contactApi.apiWebContactGet({
      perPage: 10000,
      name: getFilter('name'),
      description: getFilter('description'),
      email: getFilter('email'),
      cellphoneNumber: getFilter('cellphoneNumber'),
      contactGroup: getFilter('contactGroup'),
      type: getFilter('type'),
      externalId: getFilter('externalId'),
      emailBan: getFilter('emailBan') as boolean | undefined,
      smsBan: getFilter('smsBan') as boolean | undefined,
      callBan: getFilter('callBan') as boolean | undefined,
      whatsappBan: getFilter('whatsappBan') as boolean | undefined,
    });
    return (
      items?.map((item) => ({
        name: item.name ?? '-',
        description: item.description ?? '-',
        email: item.email ?? '-',
        cellphoneNumber: item.cellphoneNumber ?? '-',
        contactGroup:
          item.contactGroup == null
            ? '-'
            : item.contactGroup.split(', ').join('; '),
        type: item.type ?? '-',
        externalId: item.externalId ?? '-',
        emailBan: item.emailBan ? 'Yes' : 'No',
        smsBan: item.smsBan ? 'Yes' : 'No',
        callBan: item.callBan ? 'Yes' : 'No',
        whatsappBan: item.whatsappBan ? 'Yes' : 'No',
      })) ?? []
    );
  });
};
